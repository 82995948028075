<template>
  <div class="map">
    <baidu-map
      class="bm-view"
      :center="center"
      @click="getLocation"
      @ready="mapReady"
      :zoom="16"
    >
    </baidu-map>
    <!-- <p>经度:{{ center.lng }}</p>
    <p>纬度:{{ center.lat }}</p> -->
    <button @click="getCity">获取当前位置</button>
    <!-- <p>
      {{ location }}
    </p> -->
    <!-- <p>详细地址:{{ form.address }}</p> -->
  </div>
</template>

<script>
export default {
  name: "AppMaptest",

  data() {
    return {
      point: "",
      center: {
        lng: "",
        lat: "",
      },
      location: null,
    };
  },

  mounted() {
    // navigator.geolocation.getCurrentPosition(
    //   function (result) {
    //     // 得到地理定位结果的前提：1，设备支持并开启定位功能 2，用户在网页弹窗授权允许
    //     console.log(1, result); //成功的结果
    //     console.log(`当前位置的信息如下:<br>
    //                             经度是:${result.coords.longitude}<br>
    //                             纬度是：${result.coords.latitude}<br>
    //                             海拔是：${result.coords.altitude}<br>
    //                             方向是：${result.coords.heading}<br>
    //                             速度是：${result.coords.speed}<br>`);
    //   },
    //   function (err) {
    //     // 如果用户阻止获取位置，会执行第二个回调函数，也就是失败的回调
    //     console.log(2, err); //失败的信息
    //   }
    // );
  },

  methods: {
    mapReady({ BMap, map }) {
      // 默认经纬度作为中心点
      const _this = this;

      // 获取自动定位方法
      var geolocation = new BMap.Geolocation();
      // var map = new BMapGL.Map('container');
      var gc = new BMap.Geocoder();
      // 获取自动定位获取的坐标信息
      geolocation.getCurrentPosition(
        function (r) {
          var pt = r.point;
          gc.getLocation(pt, function (rs) {
            console.log(rs);
            var addComp = rs.addressComponents;
            var address = rs.address;
            var province = addComp.province;
            var city = addComp.city;
            var area = addComp.district;
            alert(`${province}:${city}:${area}:${address}`);
          });
          console.log(r);
          //可以conso.log看一下这个r，他里面包含了检索到的位置信息。下面就把两个维度信息赋值给center来定位
          _this.center = {
            lng: r.point.lng,
            lat: r.point.lat,
          };
          console.log(_this.center);
        },
        //启用高精度
        { enableHighAccuracy: true }
      );
    },

    getLocation(e) {
      console.log(e);
      this.form.lng = e.point.lng; //经度
      this.form.lat = e.point.lat; //纬度
      console.log(e.point.lng);
      console.log(e.point.lat);
      var point = new BMap.Point(e.point.lng, e.point.lat);
      var gc = new BMap.Geocoder();
      let _this = this;
      gc.getLocation(point, function (rs) {
        //地址信息
        _this.form.address = rs.address;
      });

      console.log(this.form);
      this.infoWindowShow = true;
    },

    getCity() {
      let _this = this;
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          console.log(r);
          if (r.accuracy == null) {
            alert("您已拒绝地理位置授权");
            //用户决绝地理位置授权
            return;
          } else {
            const myGeo = new BMap.Geocoder();
            myGeo.getLocation(
              new BMap.Point(r.point.lng, r.point.lat),
              (data) => {
                if (data.addressComponents) {
                  const result = data.addressComponents;
                  const location = {
                    creditLongitude: r.point.lat, // 经度
                    creditLatitude: r.point.lng, // 纬度
                    creditProvince: result.province || "", // 省
                    creditCity: result.city || "", // 市
                    creditArea: result.district || "", // 区
                    creditStreet:
                      (result.street || "") + (result.streetNumber || ""), // 街道
                  };

                  _this.center = {
                    lng: r.point.lng,
                    lat: r.point.lat,
                  };
                  _this.location = location;
                  _this.creditLongitude = location.creditLongitude;
                  _this.creditLatitude = location.creditLatitude;
                  _this.creditCity = location.creditCity;
                  // alert(this.getStatus());
                }
              }
            );
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  height: 100%;
}
.bm-view {
  width: 100%;
  height: 100%;
}
</style>
