<template>
  <div>
    <wx-open-launch-weapp
      id="launch-btn"
      appid="wx90363dc11c1572c0"
      path="pages/index/index"
    >
      <script type="text/wxtag-template">
        <style>.btn { padding: 12px }</style>
        <button class="btn">打开小程序</button>
      </script>
    </wx-open-launch-weapp>
    <button @click="go">跳转{{ url }}</button>
  </div>
</template>

<script>
import { JSSDKready } from "@/utils/sdk";

import axios from "axios";

var service = axios.create({
  baseURL: "http://192.168.1.156:20050/", // url = base url + request url
  // 20003
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
});
service.interceptors.request.use(
  (config) => {
    // config.headers['Region'] = "SG"
    config.headers["Region"] = "QG";
    return config;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);
export default {
  data() {
    return {
      wxHtml: "",
      url: "",
    };
  },
  created() {
    JSSDKready();

    let obj = JSON.stringify({
      jump_wxa: {
        path: "pages/index/index",
        query: "",
      },
      expire_type: "",
    });
    service({
      url: "/generatescheme",
      method: "post",
      data: {
        object: obj,
      },
    }).then((res) => {
      this.url = JSON.parse(res.data.data).openlink
      console.log(JSON.parse(res.data.data).openlink);

    });
  },
  methods: {
    // config() {
    //   let myUrl = window.location.href;
    //   this.$axios
    //     .get("/api/wx/open/jsdk/config", {
    //       params: {
    //         url: myUrl,
    //       },
    //     })
    //     .then((data) => {
    //       if (data.data) {
    //         wx.config({
    //           debug: false,
    //           appId: data.data.appId,
    //           timestamp: data.data.timeSpan,
    //           nonceStr: data.data.nonceStr,
    //           signature: data.data.sinature,
    //           jsApiList: ["openLocation"],
    //           openTagList: ["wx-open-launch-weapp"],
    //         });
    //         this.getHtml();
    //       }
    //     });
    // },
    getHtml() {
      let script = document.createElement("script");
      script.type = "text/wxtag-template";
      script.text = `<button class="btn blue mt1" style="height: 50px;width: 100px;">跳转小程序</button>`;
      this.wxHtml = `<wx-open-launch-weapp style="height: 50px;width: 100px;"
		                          id="launch-btn"
								    appid="wx8845a1377cb25771"
		                            username="gh_xxxxxxxx"
		                            path="pages/index/index.html"
		                          >${script.outerHTML}
		                        </wx-open-launch-weapp>`;
      this.$nextTick(() => {
        let launchButton = document.getElementById("launch-btn");
        launchButton.addEventListener("launch", function (e) {
          console.log("success", e);
        });

        launchButton.addEventListener("error", function (e) {
          console.log("fail", e);
        });
      });
    },
    go() {
      window.location.href = this.url;
    },
  },
};
</script>

<style lang="scss" scoped></style>
